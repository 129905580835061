@import '~antd/dist/antd.less';
@import '../node_modules/mapbox-gl/dist/mapbox-gl.css';

body {
  background: #364150;
}

.logo-login {
  height: 60px;
  margin: 40px auto;
  text-align: center;
}

.login {
  background: #364150;
}

.form-login {
  background:#ECEEF1;
  padding: 20px;
  margin: 40px auto;
  width: 350px;
  text-align: center;
}

.logoImgLogin{
  height: 80px;
  margin: auto;
}

.h2-login {
  color: #4FB879;
  font-weight: 400;
  font-size: 30px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.site-layout-background {
  padding: 24px;
}

.logo {
  height: 36px;
  margin: 16px;
}
  
.logoImg  {
  height:37px;
  text-align: center;
  margin-left: 13px;
}

.avatar {
  text-align: right;
  margin-right: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.logoutButton {
  margin: 5px 5px;
  padding: 5px auto;
  border: black 1px;
  background-color: #CCCCCC;
}

.send-course-end {
  text-align: center;
}

.profile-card {
  height: 300px;
}

.phone {
  font-size: 16px;
  margin-left: 200px;
}

.logo-footer {
  width: 150px;
}

.footer-text {
  margin: 10px 0;
}

.visual{
  width: 80px;
  height: 80px;
  display: block;
  float: left;
  padding-top: 10px;
  padding-left: 15px;
  margin-bottom: 15px;
  font-size: 35px;
  line-height: 35px;
}

.visual-icon {
  margin-left: -35px;
  font-size: 100px;
  line-height: 100px;
  opacity: .1;
  color: #2FAC66;
}

.details{
  position: absolute;
  right: 15px;
}

.map-page-container {
  position: relative;
  height: 800px;
  width: 100%
}

.map-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.marker-delivery {
  background-image: url('gps.png');
  background-size: cover;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.marker-pickup {
  background-image: url('merchant-icon.png');
  background-size: cover;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.map-dispatch-filter{
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #ffffff;
  padding: 6px 12px;
  font: 15px/24px monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.profil-title {
  font-weight: bold;
}

.site-page-header {
    padding: 0;
    margin: 16px 0;
    margin-top: 5px;
}

.ant-page-header-heading-title {
    color: #2FAC66;
    font-weight: 300;
}

.ant-page-header-heading-left {
    margin: 0;
}

.ant-page-header-heading .ant-avatar {
    font-size: 20px;
    color: #2FAC66;
    background: transparent;
    margin-right: 6px;
}

.ant-divider-horizontal {
    margin: 16px 0;
}

@media screen and (max-width: 767px) {
  .ant-table-tbody > tr > td {
    padding: 5px 5px;
    font-size: 12px;
  }
  
  .profile-card {
    height: 100%;
  }

  .site-layout-background {
    padding: 5px;
  }

  .logo {
    margin: 16px 16px 16px 0;
  }
    
  .logoImg  {
    margin-left: 0;
  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
  
  .profile-card {
    height: 300px;
  }

}
@primary-color: #2FAC66;